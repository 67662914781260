<template>
  <artmisads-modal
    v-model:open="props.showPaymentModal"
    title="Payment Edit"
    :width="1000"
    :onCancel="() => props.setShowPaymentModal(false)"
    :destroyOnClose="true"
  >
    <a-spin :spinning="spinning">
      <div class="payment-confirm">
        <div class="item">
          <span>ArtmisAds Account:</span>
          <span>{{detailData.publisherName}} / {{detailData.publisherEmail}}</span>
        </div>
        <div class="content border">
          <div class="item">
            <span>Bank Account Name:</span>
            <span></span>
          </div>
          <div class="item">
            <span>Routing Number:</span>
            <span></span>
          </div>
          <div class="item">
            <span>Account Number:</span>
            <span></span>
          </div>
        </div>
        <div class="content top">
          <div class="item">
            <span>Payment Amount:</span>
            <span>{{AMAZON_CURRENCY_MAPPER[detailData.marketplace]}}{{detailData.amount}}</span>
          </div>
          <div class="item">
            <span>Seller Status:</span>
            <span>{{FINANCE_SELLER_PAYMENT_STATUS_MAP[detailData.sellerPayStatus]?.label}}</span>
          </div>
          <div class="item">
            <span>Payment Status:</span>
            <span>{{FINANCE_SELLER_STATUS_MAP[detailData.status]?.label}}</span>
          </div>
        </div>
        <div class="content border">
          <div class="item">
            <span>Pending:</span>
            <span>{{AMAZON_CURRENCY_MAPPER[detailData.marketplace]}}{{detailData.pendingAmount}}</span>
          </div>
          <div class="item">
            <span>Ready To Pay:</span>
            <span>{{AMAZON_CURRENCY_MAPPER[detailData.marketplace]}}{{detailData.readyToPayAmount}}</span>
          </div>
          <div class="item">
            <span>Paid:</span>
            <span>{{AMAZON_CURRENCY_MAPPER[detailData.marketplace]}}{{detailData.paidAmount}}</span>
          </div>
        </div>
        <div class="bottom-cont">
          <div class="amount">
            <span>Amount To Pay</span>
            <a-input-number :min="0" v-model:value="amountValue"/>
          </div>
          <div class="amount">
            <span>Comment</span>
            <artmisads-input v-model:value="commentValue"/>
          </div>
        </div>
      </div>
    </a-spin>
    <template #footer>
      <div class="btn">
        <artmisads-button @click="() => props.setShowPaymentModal(false)">Cancel</artmisads-button>
        <artmisads-button type="primary" @click="financePublisherBillingsDetailsConfirmToPay">Confirm</artmisads-button>
      </div>
    </template>
  </artmisads-modal>
</template>

<script setup>
import { Modal } from 'ant-design-vue';
import { message } from "ant-design-vue";
import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';
import { ref, onBeforeMount, computed, onMounted, watch } from "vue";
import { financeApi } from "~/server/operator/finance";
import { FINANCE_SELLER_STATUS_MAP, FINANCE_SELLER_PAYMENT_STATUS_MAP } from '~/contants/operator';

const props = defineProps(["showPaymentModal", "billingId", 'setShowPaymentModal']);

const amountValue = ref('');
const commentValue = ref('');
const detailData = ref({});
const spinning = ref(false);

const getFinancePublisherBillingsDetailsPendingEdit = async () => {
  spinning.value = true;
  const params = { billingId: props.billingId };
  const res = await financeApi.getFinancePublisherBillingsDetailsPendingEdit(
    params
  );
  if (res) {
    detailData.value = res;
  }
  spinning.value = false;
};

const financePublisherBillingsDetailsConfirmToPay = async () => {
  if (!amountValue.value) {
    return message.error('Please write amount');
  }

  if (Number(amountValue.value) > (detailData.value.amount - detailData.value.paidAmount)) {
    return  Modal.error({
    title: 'Notice',
    content: `Adding this record will cause the amount of paid to exceed the amount of payment(${AMAZON_CURRENCY_MAPPER[detailData.value.marketplace]}${detailData.value.amount}).Plese check again.`,
  });
  }

  Modal.confirm({
    title: 'Notice',
    content: `Are you sure to ${AMAZON_CURRENCY_MAPPER[detailData.value.marketplace]}${amountValue.value} add to paid amount?`,
    onOk: async () => {
      spinning.value = true;
      const params = {
        billingId: props.billingId,
        amountToPay: amountValue.value,
        comment: commentValue.value
      }
      const res = await financeApi.financePublisherBillingsDetailsConfirmToPay(params);
      if (res === null) {
        message.success('Success');
        props.setShowPaymentModal(false, true);
      }
      spinning.value = false;
      }
    });
  };

onBeforeMount(() => {
  getFinancePublisherBillingsDetailsPendingEdit();
});
</script>

<style lang="less" scoped>
.payment-confirm {
  .item {
    margin-bottom: 10px;
    > span:nth-child(2) {
      color: var(--dark-1);
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.3px;
      margin-left:8px;
    }
    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
      border-radius: 12px;
      margin-left:8px;
    }
  }
  .content {
    display: flex;
    &.top {
      margin-top: 20px;
    }
    &.border {
      padding-bottom: 20px;
      border-bottom: 1px solid var(--border-4);
    }
    .item {
      width: 230px;
    }
  }
  .bottom-cont {
    margin-top: 20px;
    .amount {
      display: flex;
      width: 500px;
      margin-bottom: 20px;
      > span {
        align-self: center;
        width: 120px;
        margin-right: 8px;
      }
    }
    .receipt {
      margin-top: 20px;
    }
  }
}
</style>

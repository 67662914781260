<template>
  <div class="table" ref="tableRef">
    <artmisads-table
      :columns="columns"
      row-key="id"
      :data-source="props.dataList"
      :pagination="{
        total: props.listTotal,
        current: props.params.page,
        pageSize: props.params.size,
        showSizeChanger: true
      }"
      :change="onChangePage"
      tableLayout="fixed"
    >
      <template #bodyCell="{ column, record, text }">
        <template v-if="column.name === 'showCurrency'">
          {{AMAZON_CURRENCY_MAPPER[record.marketplace]}}{{text}}
        </template>
        
        <template v-if="column.name === 'status'">
          {{FINANCE_PAYMENT_DETAIL_STATUS_MAP[text]?.label}}
        </template>
        <template v-if="column.name === 'sellerPayStatus'">
          {{FINANCE_SELLER_PAYMENT_STATUS_MAP[text]?.label}}
        </template>
        <template v-if="column.name === 'account'">
          <div class="seller-name">
            <span>{{record.publisherName}}</span>
            <span>{{record.publisherEmail}}</span>
          </div>
        </template>
        <template v-if="column.name === 'actions'">
          <div class="actions">
            <artmisads-select-button @click="() => toDetail(record.billingId)" size="small" v-if="props.status !== 'paid'">Edit</artmisads-select-button>
            <artmisads-select-button @click="() => openHistory(record.billingId)" size="small">History</artmisads-select-button>
          </div>
        </template>
      </template>
    </artmisads-table>
  </div>
</template>
<script lang="jsx" setup>
  import { useRouter } from "vue-router";
  import moment from 'moment';
  import { ref, computed } from 'vue';
  import { message } from "ant-design-vue";
  import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';
  import { FINANCE_PAYMENT_DETAIL_STATUS_MAP, FINANCE_SELLER_PAYMENT_STATUS_MAP } from '~/contants/operator';

  const router = useRouter();
  const tableRef = ref(null);
  const props = defineProps([ 'listTotal', 'setParams', 'params', 'dataList', 'getList', 'status', 'setBillingId', 'setShowHistoryModal', 'setShowPaymentModal']);
  const columns = computed(() => {
    return props.status === 'pending' ? 
    [
      {
        title: 'Payment ID',
        dataIndex: 'billingId',
        width: 140,
      },
      {
        title: 'ArtemisAds Account',
        dataIndex: 'ArtmisAds',
        width: 120,
        name: 'account',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: 150,
        name: 'showCurrency',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        name: 'status',
        width: 140,
      },
    ] : [
      {
        title: 'Payment ID',
        dataIndex: 'billingId',
        width: 140,
      },
      {
        title: 'ArtemisAds Account',
        dataIndex: 'ArtmisAds',
        width: 120,
        name: 'account',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: 100,
        name: 'showCurrency',
      },
      {
        title: 'Seller Status',
        dataIndex: 'sellerPayStatus',
        name: 'sellerPayStatus',
        width: 100,
      },
      {
        title: 'Pending',
        dataIndex: 'pendingAmount',
        width: 150,
        name: 'showCurrency',
      },
      {
        title: 'Ready To Pay',
        dataIndex: 'readyToPayAmount',
        width: 150,
        name: 'showCurrency',
      },
      {
        title: 'Paid',
        dataIndex: 'paidAmount',
        width: 120,
        name: 'showCurrency',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        name: 'status',
        width: 100,
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        name: 'actions',
        width: 150,
      },
    ]
  })

  const onChangePage = (data, filters, sorter) => {
    const { current, pageSize } = data;
    let newParams = { ...props.params };
    newParams.page = current;
    newParams.size = pageSize;
    if (sorter) {
      const { field, order } = sorter;
      newParams.sortField = field;
      newParams.sortDirection = order === 'ascend' ? 'asc' : 'desc';
    } else {
      newParams.sortField = '';
      newParams.sortDirection = '';
    }
    props.setParams(newParams);
    props.getList();
  }

  const openHistory = id => {
    props.setBillingId(id)
    props.setShowHistoryModal(true);
  }

  const toDetail = id => {
    props.setBillingId(id)
    props.setShowPaymentModal(true);
  }

</script>
<style lang="less" scoped>
  .table {
    padding: 16px 0;
    :deep(.ant-tooltip-arrow) {
      display: none;
    }
    :deep(.ant-table) {
      .ant-table-thead {
        .ant-table-cell {
          line-height: 24px;
        }
      }
      .seller-name {
        display: flex;
        flex-direction: column;
        > span {
          &:first-child {
            font-weight: 600;
            margin-bottom: 2px;
          }
        }
      }
      .actions {
        display: flex;
        justify-content: space-between;
        button {
          align-self: center;
          &:last-child {
            margin-left: 10px;
          }
        }
        .confirm-btn {
          color: var(--primary-text);
          border-color: #fff;
          box-shadow: none;
        }
      }
    }
  }
</style>
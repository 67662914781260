<template>
  <artmisads-modal
    v-model:open="props.showHistoryModal"
    title="Edit History"
    :width="1000"
    :onCancel="() => props.setShowHistoryModal(false)"
    :destroyOnClose="true"
  >
    <a-spin :spinning="spinning">
      <div class="history-modal">
        <artmisads-table
          :columns="columns"
          row-key="id"
          :data-source="dataList"
          tableLayout="fixed"
        >
          <template #bodyCell="{ column, record, text }">
            <template v-if="column.name === 'date'">
              {{moment(text).format('YYYY-MM-DD HH:MM:SS')}}
            </template>
            <template v-if="column.name === 'showCurrency'">
              {{AMAZON_CURRENCY_MAPPER[props.marketplace]}}{{text}}
            </template>
          </template>
        </artmisads-table>
      </div>
    </a-spin>
  </artmisads-modal>
</template>

<script setup>
import moment from 'moment';
import { message } from "ant-design-vue";
import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';
import { ref, onBeforeMount, computed, onMounted, watch } from "vue";
import { financeApi } from "~/server/operator/finance";

const props = defineProps(["showHistoryModal", "billingId", 'setShowHistoryModal', 'marketplace']);

const dataList = ref([]);
const spinning = ref(false);

const columns = ref([{
    title: 'Date',
    dataIndex: 'paymentAt',
    name: 'date'
  },
  {
    title: 'Amount',
    dataIndex: 'paymentAmount',
    width: 120,
    name: 'showCurrency'
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
  },
])

const getFinancePublisherBillingsDetailsPaymentHistory = async () => {
  spinning.value = true;
  const params = { billingId: props.billingId };
  const res = await financeApi.getFinancePublisherBillingsDetailsPaymentHistory(params);
  console.log(res)
  if (res && res.length) {
    dataList.value = res;
  }
  spinning.value = false;
};

onBeforeMount(() => {
  getFinancePublisherBillingsDetailsPaymentHistory();
});
</script>

<style lang="less" scoped>
.history-modal {
}
</style>

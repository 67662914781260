import { axios as client } from './apiAdmin';
import { exportFile } from '../requestDownloadFile';
export const financeApi = {
    // 获取商家近 30 天的广告活动聚合报表
    getFinanceSellerBillings: data => {
        return client.get('finance/seller/billings', { params: data }).then(res=>res.data);
    },
    getFinanceSellerBillingsAgg: data => {
      return client.get('finance/seller/billings/agg', { params: data }).then(res=>res.data);
    },
    financeSellerBillingsConfirm: data => {
      return client.post('finance/seller/billings/confirm', data).then(res=>res.data);
    },
    financeSellerBillingsConfirmReview: data => {
      return client.post('finance/seller/billings/confirm-review', { params: data }).then(res=>res.data);
    },
    getFinanceSellerBillingsDetailsList: data => {
      return client.get('finance/seller/billings/details', { params: data }).then(res=>res.data);
    },
    getFinanceSellerDetailInfo: data => {
      return client.get(`finance/seller/billings/${data.id}`).then(res=>res.data);
    },
    getFinanceSellerBillingsDetailsPendingReview: data => {
      return client.get('finance/seller/billings/details/pending-review', { params: data }).then(res=>res.data);
    },
    financeSellerBillingsDetailsConfirmPayment: data => {
      return client.post('finance/seller/billings/details/confirm-payment', data).then(res=>res.data);
    },
    downloadSellerPaymentConfirmTemplateFile: (data) => {
      return exportFile('finance/seller/billings/details/download-payment-confirm-template-file', data.qs, data.fileName,  data.platform);
    },
    updaloadSellerPaymentConfirmFile: data => {
      return client.post('finance/seller/billings/details/upload-payment-confirm-file', data).then(res => res.data);
    },
    updaloadSellerBillingsDetailsConfirmBulkPayment: data => {
      return client.post('finance/seller/billings/details/confirm-bulk-payment', data).then(res => res.data);
    },
    getFinanceSellerBillingInvoice: data => {
      return client.get('finance/seller/billings/invoice', { params: data }).then(res=>res.data);
    },
    financeSellerBillingsDetailsNotifyEmail: data => {
      return client.post('finance/seller/billings/details/notify-email', data).then(res=>res.data);
    },
    financeSellerBillingsDetailsNotifyEmailToAll: data => {
      return client.post('finance/seller/billings/notify-email-to-all', data).then(res=>res.data);
    },

    getFinancePublisherBillingsAgg: data => {
      return client.get('finance/publisher/billings/agg', { params: data }).then(res=>res.data);
    },
    getFinancePublisherBillings: data => {
      return client.get('finance/publisher/billings', { params: data }).then(res=>res.data);
    },
    getFinancePublisherBillingsDetailsList: data => {
      return client.get('finance/publisher/billings/details', { params: data }).then(res=>res.data);
    },
    getFinancePublisherDetailInfo: data => {
      return client.get(`finance/publisher/billings/${data.id}`).then(res=>res.data);
    },
    getFinancePublisherBillingsDetailsPendingEdit: data => {
      return client.get('finance/publisher/billings/details/pending-edit', { params: data }).then(res=>res.data);
    },
    financePublisherBillingsDetailsConfirmToPay: data => {
      return client.post('finance/publisher/billings/details/confirm-to-pay', data).then(res=>res.data);
    },
    financePublisherBillingsConfirm: data => {
      return client.post('finance/publisher/billings/confirm', data).then(res=>res.data);
    },
    getFinancePublisherBillingsDetailsPaymentHistory: data => {
      return client.get('finance/publisher/billings/details/payment-history', { params: data }).then(res=>res.data);
    }
}